import routesNames from "@/router/routesNames";

export default [
  {
    path: "*",
    name: "pageNotFound",
    redirect: routesNames.login,
    component: () => import("./page-not-found.vue"),
  },
];
