import routesNames from "@/router/routesNames";

export default [
  {
    path: "/login",
    name: routesNames.login,
    component: () => import("./login.vue"),
    meta: {
      title: "Entrar",
      requiresAuth: false,
      anonymousOnly: true,
    },
  },
];
