import routesNames from "@/router/routesNames";

export default [
  {
    path: "/receber-pedidos",
    name: routesNames.pickOrders,
    component: () => import("./PickOrders.vue"),
    meta: {
      title: "Receber Pedidos",
      requiresAuth: true,
      anonymousOnly: false
    }
  }
];
