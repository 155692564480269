import { routes as Login } from "@/views/login/index";

export default [
  {
    path: "/auth",
    name: "auth",
    component: () => import("./auth.vue"),
    children: [...Login],
  },
];
