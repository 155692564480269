// import axios from "axios";
import {
  IUser,
  IUserLoginRequestParams,
  IRealeseUserAccess,
  IUserRequest,
  IStateFederation,
  ICity,
  IUserListRequest,
  IUserAuthorizations,
  IUserGender,
  IUserTypes,
  IUserStatus,
  IUserMaritalStatus,
  IRegional,
} from "../models";
import ApiBase from "./ApiBase";

const LOGIN_PATH = "/login/admin";
const PROFILE_PATH = "/dash/profile";
const USER_PATH = "/dash";
const ADMIN = "/admin";
const USERS = "/users";
const STATES = "/states";

/**
 * Verifica junto ao servidor os dados informados pelo usuário
 * @param { IUserLoginRequestParams } params - Email e senha do usuário
 * @returns { IUserLoginRequestParams } - Credenciais de acesso do usuário
 */
export const UserLogin = async (
  params: IUserLoginRequestParams
): Promise<string> => {
  const res = await ApiBase.post(`${LOGIN_PATH}`, params);
  return res?.data?.access_token as string;
};

/**
 * Exibe dados do usuário logado
 * @returns { IUser } - Lista informações do usuário
 */
export const UserPerfil = async (): Promise<IUser> => {
  const res = await ApiBase.get(`${PROFILE_PATH}`);
  return res?.data as IUser;
};

/**
 * Exibe os usuário logados
 * @returns { IRealeseUserAccess } - Lista de usuários para bloqueio/liberação
 */
export const UserReleaseAccess = async (): Promise<
  IRealeseUserAccess[] | []
> => {
  const res = await ApiBase.get(`${ADMIN}${USERS}/access`);
  return res?.data as IRealeseUserAccess[];
};

/**
 * Libera acesso do usuário ao sistema de acordo com o id passado
 * @param { number } id - Id do usuário para liberação
 * @param { IRealeseUserAccess } params - Dados do usuário
 * @returns { IRealeseUserAccess } - Dados de liberação alterado
 */
export const ReleaseUser = async (
  id: number,
  params: IRealeseUserAccess
): Promise<IRealeseUserAccess> => {
  const res = await ApiBase.put(`${ADMIN}${USERS}/access/allow/${id}`, params);
  return res?.data as IRealeseUserAccess;
};

/**
 * Bloqueia acesso do usuário ao sistema de acordo com o id passado
 * @param { number } id - Id do usuário para bloqueio
 * @param { IRealeseUserAccess } params - Dados do usuário
 * @returns { IRealeseUserAccess } - Dados de bloqueio alterado
 */
export const BlockUser = async (
  id: number,
  params: IRealeseUserAccess
): Promise<IRealeseUserAccess> => {
  const res = await ApiBase.put(`${ADMIN}${USERS}/access/block/${id}`, params);
  return res?.data as IRealeseUserAccess;
};

/**
 * Exibe os usuários
 * @returns { IUserListRequest } - Lista os usuários
 */
export const UserList = async (): Promise<IUserListRequest[] | []> => {
  const res = await ApiBase.get(`${USER_PATH}${ADMIN}${USERS}`);
  return res?.data.data as IUserListRequest[];
};

/**
 * Insere dados do usuário
 * @param { IUserRequest } user - Dados para adicionar
 * @returns { IUserRequest } - Usuário adicionado
 */
export const UserInsertRequest = async (
  user: IUserRequest
): Promise<IUserRequest> => {
  const res = await ApiBase.post(`${USER_PATH}${ADMIN}${USERS}`, user);
  return res?.data as IUserRequest;
};

/**
 * Seleciona o usuário de acordo com o id informado
 * @param { number } id - Id do usuário para seleção
 * @returns { IUserListRequest } - Usuário com o id informado
 */
export const UserFetchOneRequest = async (
  id: number
): Promise<IUserListRequest> => {
  const res = await ApiBase.get(`${USER_PATH}${ADMIN}${USERS}/${id}`);
  return res?.data as IUserListRequest;
};

/**
 * Altera dados do usuário de acordo com o id informado
 * @param { number } id - Id do usuário para edição
 * @param { IUserRequest } user - Dados do usuário
 * @returns { IUserRequest } - Dados do usuário alterado
 */
export const UserUpdateRequest = async (
  id: number,
  user: IUserRequest
): Promise<IUserRequest> => {
  const res = await ApiBase.put(`${USER_PATH}${ADMIN}${USERS}/${id}`, user);
  return res?.data as IUserRequest;
};

/**
 * Remove um usuário
 * @param { number } id - Id do usuário para remoção
 */
export const UserRemoveRequest = async (id: number): Promise<void> => {
  await ApiBase.delete(`${ADMIN}${USERS}//${id}`);
};

/**
 * Mostra os Estados brasileiro
 * @returns { IStateFederation } - Lista de estados
 */
export const StateFederation = async (): Promise<IStateFederation[] | []> => {
  const res = await ApiBase.get(`${USER_PATH}${ADMIN}${STATES}`);
  return res?.data as IStateFederation[];
};

/**
 * Mostra as cidades de acordo com o estado
 * @param uf
 * @returns { ICity } - Lista de cidades
 */
export const CityState = async (id: number): Promise<ICity[] | []> => {
  const res = await ApiBase.get(`${USER_PATH}${ADMIN}${STATES}/${id}`);
  return res?.data.cities as ICity[];
};

// /**
//  * Mostra as profissões
//  * @returns { IUserProfession } - Lista de profissões
//  */
// export const UserProfession = async (): Promise<IUserProfession[] | []> => {
//   const res = await ApiBase.get(`${USER_PATH}${ADMIN}${USERS}/types`);
//   return res?.data as IUserProfession[];
// };

// /**
//  * Mostra as autoriazções
//  * @returns { IUserAuthorizationType } - Lista de autorizações
//  */
// export const UserAuthorizations = async (): Promise<
//   IUserAuthorizationType[] | []
// > => {
//   const res = await ApiBase.get(`${ADMIN}${USERS}/authorizations`);
//   return res?.data as IUserAuthorizationType[];
// };
// export const UserRegister;
//Update

/**
 * Mostra as autorizações
 * @returns { IUserAuthorizationType } - Lista de autorizações
 */
export const UserAuthorizations = async (): Promise<
  IUserAuthorizations[] | []
> => {
  const res = await ApiBase.get(`${USER_PATH}${ADMIN}${USERS}/authorizations`);
  return res?.data as IUserAuthorizations[];
};

/**
 * Mostra os generos
 * @returns { IUserGender } - Lista de generos
 */
export const UserGenders = async (): Promise<IUserGender[] | []> => {
  const res = await ApiBase.get(`${USER_PATH}${ADMIN}${USERS}/gender`);
  return res?.data as IUserGender[];
};

/**
 * Mostra os Estados civis cadastrados no banco
 * @returns { IUserMaritalStatus } - Lista de estados civis
 */
export const UserMaritalStatus = async (): Promise<
  IUserMaritalStatus[] | []
> => {
  const res = await ApiBase.get(`${USER_PATH}${ADMIN}${USERS}/maritalStatus`);
  return res?.data as IUserMaritalStatus[];
};

/**
 * Mostra os Tipos de usuarios
 * @returns { IUserTypes } - Lista de Tipos de usuarios
 */
export const UserTypes = async (): Promise<IUserTypes[] | []> => {
  const res = await ApiBase.get(`${USER_PATH}${ADMIN}${USERS}/types`);
  return res?.data as IUserTypes[];
};

/**
 * Mostra os Status de usuarios
 * @returns { IUserStatus } - Lista de Status de usuario
 */
export const UserStatus = async (): Promise<IUserStatus[] | []> => {
  const res = await ApiBase.get(`${USER_PATH}${ADMIN}${USERS}/status`);
  return res?.data as IUserStatus[];
};

/**
 * Mostra os Regionais
 * @returns { IRegional } - Lista de Regionais
 */
export const UserRegionals = async (): Promise<IRegional[] | []> => {
  const res = await ApiBase.get(`${USER_PATH}${ADMIN}/regionals`);
  return res.data.data as IRegional[];
};
