import Vue from "vue";

/**
 * Exibe um alerta tipo toast para o usuário
 * @param { string } message - Mensagem que será exibida no alerta
 */
export const notificationSuccess = (message: string): void => {
  Vue.swal({
    toast: true,
    position: "top-end",
    timer: 5000,
    showConfirmButton: false,
    timerProgressBar: true,
    icon: "success",
    titleText: message,
  });
};

/**
 * Exibe um alerta tipo toast para o usuário
 * @param { string } message - Mensagem que será exibida no alerta
 */
export const notificationError = (message: string): void => {
  Vue.swal({
    toast: true,
    position: "top-end",
    timer: 5000,
    showConfirmButton: false,
    timerProgressBar: true,
    icon: "error",
    titleText: message,
  });
};

/**
 * Exibe um alerta tipo toast para o usuário
 * @param { string } message - Mensagem que será exibida no alerta
 */
export const notificationWarning = (message: string): void => {
  Vue.swal({
    toast: true,
    position: "top-end",
    timer: 5000,
    showConfirmButton: false,
    timerProgressBar: true,
    icon: "warning",
    titleText: message,
  });
};
