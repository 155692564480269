import routesNames from "@/router/routesNames";
import { routes as NewOrder } from "@/views/new-order/index";
import { routes as Orders } from "@/views/orders/index";
import { routes as PickOrders } from "@/views/pick-orders/index";
export default [
  {
    path: "/",
    name: routesNames.home,
    component: () => import("./home.vue"),
    meta: {
      title: "Início"
    },
    children: [...NewOrder, ...Orders, ...PickOrders]
  }
];
