import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";

import LocalStorageUtils from "@/utils/LocalStorageUtils";
import { IUserState } from "../models";
import { IUser, IUserLoginRequestParams } from "@/services/models";
import { UserLogin, UserPerfil } from "@/services/api/ApiUser";
import store from "../index";
import modulesNames from "../modulesNames";

const AUTH_TOKEN_KEY = "TOKEN";

@Module({ dynamic: true, namespaced: true, store, name: modulesNames.user })
class User extends VuexModule implements IUserState {
  private _currentUser?: IUser | null = null;
  private _token?: string = LocalStorageUtils.getItem(AUTH_TOKEN_KEY);

  get user(): IUser | undefined | null {
    return this._currentUser;
  }

  get token(): string {
    return this._token || "";
  }

  get isLoggedIn(): boolean {
    return !!this._currentUser;
  }

  @Mutation
  private SET_CURRENT_USER(currentUser?: IUser): void {
    this._currentUser = currentUser;
  }

  @Mutation
  private SET_TOKEN(token?: string): void {
    if (token) {
      this._token = token;
      LocalStorageUtils.setItem(AUTH_TOKEN_KEY, token);
    } else {
      this._token = undefined;
      LocalStorageUtils.removeItem(AUTH_TOKEN_KEY);
    }
  }

  @Action({ rawError: true })
  async fetchCurrentUser(): Promise<void> {
    const user = await UserPerfil();
    this.SET_CURRENT_USER(user);
  }

  @Action({ rawError: true })
  async login(params: IUserLoginRequestParams): Promise<void> {
    const token = await UserLogin(params);
    this.SET_TOKEN(token);
  }

  @Action({ rawError: true })
  logout(): void {
    this.SET_TOKEN(undefined);
    this.SET_CURRENT_USER(undefined);
    // limpar localstorage
    LocalStorageUtils.removeItem(AUTH_TOKEN_KEY);
  }

  @Action({ rawError: true })
  async completeAuth(): Promise<void> {
    if (this.token && !this.isLoggedIn) await this.fetchCurrentUser();
  }
}

export default getModule(User);
