interface IModulesNames {
  user: string;
  cars: string;
}

const modulesNames: Readonly<IModulesNames> = {
  user: "user",
  cars: "cars",
};

export default modulesNames;
