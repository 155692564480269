export interface IRoutesNames {
  home: string;
  login: string;
  newOrder: string;
  orders: string;
  pickOrders: string;
}

const routesNames: Readonly<IRoutesNames> = {
  home: "home",
  login: "login",
  newOrder: "new-order",
  orders: "orders",
  pickOrders: "pickOrders"
};

declare module "vue/types/vue" {
  interface Vue {
    $routesNames: IRoutesNames;
  }
}

export default routesNames;
