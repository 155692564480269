import routesNames from "@/router/routesNames";

export default [
  {
    path: "/pedidos",
    name: routesNames.orders,
    component: () => import("./Orders.vue"),
    meta: {
      title: "Pedidos",
      requiresAuth: true,
      anonymousOnly: false
    }
  }
];
