import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import VueI18n from "vue-i18n";
import { messages, defaultLocale } from "@/i18n";
import Vuelidate from "vuelidate";
import moment from "moment";
import VueSweetalert2 from "vue-sweetalert2";
import InputFacade from "vue-input-facade";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "sweetalert2/dist/sweetalert2.min.css";
import routesNames from "./router/routesNames";

import mdiVue from "mdi-vue/v2";
import * as mdijs from "@mdi/js";

Vue.use(mdiVue, { icons: mdijs });
Vue.prototype.moment = moment;

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(BootstrapVueIcons);
// Vue i18n
Vue.use(VueI18n);
// Vue Vuelidate
Vue.use(Vuelidate);
// Vue SweetAlert
Vue.use(VueSweetalert2);
Vue.prototype.$routesNames = routesNames;

const i18n = new VueI18n({
  messages,
  locale: defaultLocale
});

const optionsFacade = {
  name: "mask",
  tokens: {
    "#": { pattern: /\d/ },
    A: { pattern: /[a-z]/i },
    N: { pattern: /[0-9a-z]/i },
    X: { pattern: /./ }
  }
};

Vue.use(InputFacade, optionsFacade);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount("#app");
