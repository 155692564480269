import routesNames from "@/router/routesNames";

export default [
  {
    path: "/novo-pedido",
    name: routesNames.newOrder,
    component: () => import("./NewOrder.vue"),
    meta: {
      title: "Novo Pedido",
      requiresAuth: true,
      anonymousOnly: false,
    },
  },
];
